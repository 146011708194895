import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";

import Character from "../../assets/img/character-2.png";

import Button from "components/ui/button";
import { IChallenge } from "services/challenges.service";

export function AchievementModal(props: any) {
  // const modalID = "achievement";

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<IChallenge>();
  const { refreshUser } = useContext(AuthContext);
  // const { openModal, closeModal } = useContext(ModalContext);
  // const { children } = props;

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    setIsOpen(false);
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    const listener = async ({ data }: any) => {
      if (data.type === "achievement") {
        setIsOpen(true);
        setModalData(data.value);
        refreshUser();
      }
    };
    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, [refreshUser]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div className="flex flex-col justify-center">
            <img className="block z-10" src={Character} alt="Character" />
            <div className="-mt-[70px] min-h-[50vh] w-[90%] md:min-w-[50vh] relative bg-grey-dark border-4 border-white border-solid rounded-3xl pt-10 p-3 flex flex-col items-center space-y-3 flex-none mx-5">
              <p className="text-white text-center text-lg">
                You've completed a challenge
              </p>
              <p className="text-gold font-bold text-3xl">
                {modalData?.challenge}
              </p>

              {modalData?.badgeUrl && (
                <img
                  className="w-[108px] h-[108px] object-contain rounded-full block border-4 border-white border-solid  z-10"
                  src={modalData?.badgeUrl}
                  alt={modalData?.challenge}
                />
              )}

              <div className="py-3 relative z-10">
                <Button onClick={() => handleCloseModal()}>OKAY!</Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
