import { AuthContext } from 'context/AuthContext';
import { formatDistance } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { RiCoinsLine } from 'react-icons/ri';
import { getAchievements } from 'services/achievement.service';
import { getAllChallenges } from 'services/challenges.service';

export default function ChallengeActivity() {
  const [data, setData] = useState<any[]>();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function loadData() {
      try {
        const challenges = await getAllChallenges(user?._id);
        const result = await getAchievements({
          $match: {
            player: user?._id
          },
        });
        const preparedResult = result.map((item) => {
          const challenge = challenges.find((challenge) => challenge._id === item.item);
          return { ...item, challenge: challenge };
        });
        setData(preparedResult);
      } catch (e) {}
    }

    if (user) {
      loadData();
    }
  }, [user]);

  return (
    <div className="h-full relative flex flex-col w-full bg-black bg-opacity-90 divide-y-2 divide-white divide-opacity-25">
      {/* <div className="flex flex-row text-white items-center justify-between p-3">
        <h1 className="text-2xl">Recent Achievements</h1>
        <button type="button">
          <TbLayoutSidebarRightCollapse size={24} />
        </button>
      </div> */}

      {(!data || !data.length) && (
        <h2 className="text-xl p-3 text-white text-center">
          <em>You haven't completed any challenge yet.</em>
        </h2>
      )}

      <div className="relative">
        <div className='space-y-2'>
          {data &&
            data.length &&
            data.map((item, index) => {
              return (
                <div key={item._id} className="py-2 px-3 space-y-1 border border-gray-700 rounded-lg">
                  <div className="text-white text-xs text-right text-gray-400">
                    {formatDistance(new Date(item.time), new Date(), { addSuffix: true })}
                  </div>
                  <div className="text-white flex justify-between">
                    {item.type === 0 && item.total > 0 && (
                      <div className='flex flex-row items-center space-x-1'>
                        <span>You earned:</span> <b>{item.total}</b> {item.item === 'points' ? <div>XP</div> : <RiCoinsLine size={16} />}
                      </div>
                    )}
                    {item.type === 0 && item.total < 0 && (
                      <div className='flex flex-row items-center space-x-1 text-red-300'>
                        <span>You spent:</span> <b>{Math.abs(item.total)}</b> {item.item === 'points' ? <div>XP</div> : <RiCoinsLine size={16} />}
                      </div>
                    )}
                    {item.type === 2 && (
                      <div className='flex flex-row items-center space-x-1'>
                        <span>Purchased a new item in the shop</span>
                      </div>
                    )}
                    {item.type === 3 && (
                      <div className='flex flex-row items-center space-x-1'>
                        <b className='text-blue-300'>Level Up!</b>
                      </div>
                    )}
                    {item.type === 1 && (
                      <>
                      {item.challenge && item.challenge.challenge && (
                      <div>
                        <div className="text-bold text-yellow-500 leading-none text-sm">
                          Completed {item.challenge.challenge}
                        </div>
                        {/* {item.challenge.points && (
                          <>
                            {item.challenge.points.map((point: any) => (
                              <div
                                key={point.category}
                                className="border rounded-xl px-1 flex flex-row items-center space-x-1"
                              >
                                <b>{point.total}</b>{' '}
                                {point.category === 'points' ? <div>XP</div> : <RiCoinsLine size={16} />}
                              </div>
                            ))}
                          </>
                        )} */}
                      </div>)}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
