/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';

import Box from 'components/ui/box';
import { H1 } from 'components/typography';
import ArticlePlaceholder from 'components/ui/article-placeholder';
import { BiGift } from 'react-icons/bi';
import { useMediaQuery } from 'react-responsive';
import { getVirtualGoods } from 'services/store.service';
import { AiFillLock } from 'react-icons/ai';
import { RiCoinsLine } from 'react-icons/ri';
import Button from 'components/ui/button';
import { Character } from 'components/character';

export function Shop() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 819px)' });

  function handleClick(good: any) {
    if (!good || !hasPermission(good)) return;
    navigate('/store/' + good._id);
  }

  function hasPermission(good: any) {
    // return user?.extra.type === 'member' || good.extra.public
    return true;
  }

  function isItemPurchased(good: any) {
    return user?.catalog_items[good._id];
  }

  function getItemRequirements(good: any) {
    const requirement = good.requires
      .filter((item: any) => item.type === 0)
      .reduce((acc: any, item: any) => {
        acc = item.total;
        return acc;
      }, -1);
    return requirement;
  }

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    async function loadData() {
      const data = await getVirtualGoods();
      const items = data.filter((item: any) => {
        return item.catalogId === 'wowler';
      });
      setData(items);
      setLoading(false);
    }

    loadData();
  }, [user]);

  if (!user) return null;

  return (
    <div className="md:container mx-auto">
      <div className="absolute top-10 left-10">
        <Link to="/" children={<Button>BACK</Button>} />
      </div>

      <Box>
        <div className="text-white flex flex-row px-1 pb-3 md:py-3 space-x-5 items-center">
          <div className="w-16 h-16 flex-0 md:w-24 md:h-24 border rounded-full bg-black text-white flex flex-row items-center justify-center">
            <BiGift size={isTabletOrMobile ? 32 : 64} />
          </div>
          <div className="flex-1 leading-tight">
            <H1>Gamma•X Store</H1>
            <p>Use your points to claim the available items</p>
          </div>
        </div>

        <div className="flex flex-col items-center p-5">
          <h1 className="text-xl 2xl:text-2xl font-bold py-3 border p-2 px-4 rounded-full">Coming soon</h1>

          <Character random />
        </div>

        {/* <Link to="./teste">ABRIR TESTE</Link> */}

        {(loading || !data) && (
          <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 gap-3">
            <ArticlePlaceholder />
            <ArticlePlaceholder />
            <ArticlePlaceholder />
            <ArticlePlaceholder />
            <ArticlePlaceholder />
            <ArticlePlaceholder />
            <ArticlePlaceholder />
            <ArticlePlaceholder />
            <ArticlePlaceholder />
            <ArticlePlaceholder />
          </div>
        )}

        {!loading && data && data.length > 0 && (
          <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 gap-3">
            {data.map((good: any) => (
              <div
                key={good._id}
                className={`cursor-pointer flex flex-col justify-start items-center`}
                onClick={() => {
                  handleClick(good);
                }}
              >
                <div className="flex-0 h-40 w-full bg-gray-100 rounded-md overflow-hidden">
                  {good.image && (
                    <img src={good.image?.medium?.url} alt="item" className="w-full h-full object-contain" />
                  )}
                </div>
                <p className="flex-1 flex items-center justify-center leading-4 text-center p-2 text-sm">{good.name}</p>
                {!isItemPurchased(good) && (
                  <p className="flex itemd-center justify-center text-center font-bold">
                    {!hasPermission(good) ? (
                      <AiFillLock size={24} className="mr-2" />
                    ) : getItemRequirements(good) > 0 ? (
                      <span className="flex">
                        {getItemRequirements(good)}
                        <RiCoinsLine size={20} className="text-yellow-500" />
                      </span>
                    ) : (
                      <span className="text-yellow-500">PURCHASE</span>
                    )}
                  </p>
                )}
                {isItemPurchased(good) && (
                  <p className="flex itemd-center justify-center text-center text-sm text-green-500 font-bold">
                    PURCHASED
                  </p>
                )}
              </div>
            ))}
          </div>
        )}
      </Box>

      <Outlet />
    </div>
  );
}
