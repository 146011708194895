import { AiOutlineUser, AiOutlineNotification } from 'react-icons/ai';
import {BiGift, BiHome } from 'react-icons/bi';
import { TbRocket } from 'react-icons/tb';

const navIconSize = 36;

export const navLinks = [
	{ title: "Home", route: "/", color : "blue-500", icon:<BiHome size={navIconSize} />, tab: true },
	// { title: "Challenges", route: "/challenges", color : "blue-500", icon:<BiBadgeCheck size={navIconSize} />, tab: true },
	{ title: "Ranking", route: "/ranking", color:"blue-500", icon:<TbRocket size={navIconSize} />, tab: false },
	{ title: "News", route: "/news", color:"blue-500", icon:<AiOutlineNotification size={navIconSize} />, tab: true },
	{ title: "Store", route: "/store", color : "blue-500", icon:<BiGift size={navIconSize} />, tab: true },
	{ title: "Profile", route: "/profile", color : "blue-500", icon:<AiOutlineUser size={navIconSize} />, tab: false },
	// { title: "Community", route: "/community", color : "blue-500", icon:<AiOutlineUser size={navIconSize} />, tab: true },
];
