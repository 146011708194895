import ArticlePlaceholder from 'components/ui/article-placeholder';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { fetchBanners } from 'services/banners.service';
import { IBanners } from 'services/banners.service';

import './style.scss';

export function Slide() {
  const [loading, setLoading] = useState(true);
  const [banners, SetBanners] = useState<IBanners[]>();

  useEffect(() => {
    setLoading(true);
    async function loadData() {
      const data = await fetchBanners();
      SetBanners(data);

      setLoading(false);
    }

    loadData();
  }, []);

  function renderLoading() {
    return (
      <>
        <ArticlePlaceholder />
      </>
    );
  }

  function renderList() {
    return (
      <div className="mb-10">
        <Carousel
          useKeyboardArrows
          swipeable={true}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay
          infiniteLoop
          centerMode
          renderIndicator={(onClickHandler: any, isSelected: any, index: any) => {
            if (isSelected) {
              return (
                <li
                  className="w-2 h-2 rounded-full bg-blue-custom"
                  aria-label={`Selecionado: Destaque ${index + 1}`}
                  title={`Selecionado: Destaque ${index + 1}`}
                />
              );
            }
            return (
              <li
                className="w-2 h-2 rounded-full border border-white border-solid"
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                title={`Destaque ${index + 1}`}
                aria-label={`Destaque ${index + 1}`}
              />
            );
          }}
        >
          {banners?.map((item) => (
            <div className="banner-item relative" key={item._id}>
              <img alt={item.title} src={item.image.original.url} />
              {item.link ? <a href={item.link} target="_blank" rel="noreferrer" className='absolute top-0 left-0 w-full h-full'>
                <p className="absolute top-0 left-0 px-2 w-full h-full flex flex-row items-center justify-center text-center font-bold">{" "}</p>
              </a> : null }
            </div>
          ))}
        </Carousel>
      </div>
    );
  }

  return <>{loading ? renderLoading() : renderList()}</>;
}
