import Button from 'components/ui/button';
import { AuthContext } from 'context/AuthContext';
import { ModalContext } from 'context/ModalContext';
import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { registerFeedbackMessage } from 'services/feedback.service';
import { inputClass } from 'styles';

// const wowCheckin = require('assets/img/btn-wow-checkin.png');

export function FeedbackModal() {
  const modalID = 'feedback';

  const [modalIsOpen, setIsOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { openModal, closeModal } = useContext(ModalContext);

  // const [challenge, setChallenge] = useState<any>();
  const [details, setDetails] = useState('');
  const [messageSent,setMessageSent] = useState(false);
  const [loading,setLoading] = useState(false);

  function afterOpenModal() {}

  async function handleOnClick() {
    
    if(messageSent){
      closeModal();
    }

    setLoading(true);
    setMessageSent(false);

    try {
      await registerFeedbackMessage({
        user: user?._id,
        message: details,
        time: new Date().getTime(),
      });

      // closeModal();
      setMessageSent(true);
      setLoading(false);

    } catch (e) {
      //
      setLoading(false);
    }
  }

  useEffect(() => {
    setMessageSent(false);
    setIsOpen(openModal === modalID);
  }, [openModal]);

  ReactModal.setAppElement('#modal');

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        contentLabel="Example Modal"
        portalClassName=""
        shouldCloseOnEsc
        overlayClassName="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div className="flex flex-col md:w-1/2 bg-white p-5 rounded-xl ">
            <div className="flex flex-1 flex-row justify-between items-start py-3 relative">
              <div className='flex-1'>
              <p className="text-2xl font-bold text-center relative">WOWler Feedback</p>
              <p className="text-2xl font-bold text-center relative">Do you need help or want to share feedback?</p>
              </div>
              <Button className='absolute top-0 right-0' onClick={() => closeModal()}>CLOSE</Button>
            </div>

            <div className="overflow-auto space-y-2">
              <div className="flex-1 space-y-2">
                <p>Message</p>
                <textarea readOnly={messageSent} className={inputClass} onChange={(e) => setDetails(e.target.value)}></textarea>
              </div>
            </div>

            {messageSent && <div className="mt-5 text-center text-lg font-bold text-green-600">
              Thanks for your feeback!
            </div>}

            {loading && <div className="mt-5 text-center text-lg font-bold text-blue-600">
             Sending message...
            </div>}

            {!messageSent && !loading && <Button className="m-5" onClick={handleOnClick}>
              SEND MESSAGE
            </Button>}

            {messageSent && <Button className="m-5" onClick={handleOnClick}>
              CLOSE WINDOW
            </Button>}

            <p className='text-center'>You can also use this form to request your data is removed.</p>
          </div>
        )}
      />
    </>
  );
}
