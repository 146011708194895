import { TabBarItem } from "./TabBarItem";
import { navLinks } from "types/navigation";

import "./style.scss";

export function TabBar() {
	return (
		<div className="tabbar fixed md:hidden z-30 left-0 right-0 shadow-lg">
			<div className="bg-green-custom h-16 flex">
				{navLinks.map((item, index) => (
					<TabBarItem
						id={`navitem-${index}`}
						key={item.title}
						label={item.title}
						target={item.route}
					>
						{item.icon}
					</TabBarItem>
				))}
			</div>
		</div>
	);
}
