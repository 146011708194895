import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './context/AuthContext';
import { RouteList } from './routes';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import { ModalProvider } from 'context/ModalContext';
import ToastProvider from 'context/ToastContext';

import { NetworkingProvider } from 'context/NetworkingContext';
import { WebsocketProvider } from 'context/WebsocketContext';
import { ChatProvider } from 'context/ChatContext';
// import ReactTooltip from 'react-tooltip';

const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <WebsocketProvider>
        <ChatProvider>
          <ModalProvider>
            <NetworkingProvider>
              <RouteList />
              <ToastProvider />
              {/* <ReactTooltip id='global' effect="float" /> */}
            </NetworkingProvider>
          </ModalProvider>
        </ChatProvider>
      </WebsocketProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default App;
