/* eslint-disable react-hooks/exhaustive-deps */
import ArticlePlaceholder from 'components/ui/article-placeholder';
import { AuthContext } from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { getBadges } from 'services/badges.service';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import { IChallenge } from 'services/challenges.service';
import { BiRocket } from 'react-icons/bi';

export function BadgesList() {
  const [loading, setLoading] = useState(true);
  const [challenges, setChallenges] = useState<IChallenge[]>();
  const { user } = useContext(AuthContext);

  function isQuestCompleted(challengeId: string) {
    if (!user) return 0;
    return user.challenges[challengeId] || 0;
  }

  function getQuestProgress(challengeId: string) {
    if (!user) return false;
    try {
      const challengeProgress = user?.challenge_progress?.find(
        (item) => item.challenge === challengeId
      )?.percent_completed;
      return challengeProgress;
    } catch (e) {
      return 0;
    }
  }

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    async function loadData() {
      const data = await getBadges(user!._id);
      const preparedData = data.filter(item=>isQuestCompleted(item._id));
      setChallenges(preparedData);
      setLoading(false);
    }

    loadData();
  }, [user]);

  function renderLoading() {
    return (
      <div className='space-y-2'>
        <ArticlePlaceholder />
        <ArticlePlaceholder />
        <ArticlePlaceholder />
      </div>
    );
  }

  function renderList() {
    if (!challenges?.length) {
      return (
        <div className="flex flex-row space-x-3 items-center mb-5">
          <div className="rounded-full border border-white p-3">
            <BiRocket size={32} />
          </div>
          <h1 className="text-lg">Complete missions to unlock new badges.</h1>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-3 gap-x-4 gap-y-2">
        {challenges?.map((item) => {
          const completed = isQuestCompleted(item._id);
          const progress = getQuestProgress(item._id);

          if (!completed) return null;

          return (
            <div key={item._id} className="">
              <div className="flex justify-center ml-1 mr-2 relative">
                {progress && progress > 0 && (
                  <div className="absolute h-[70px] w-[70px] -top-1">
                    <CircularProgressbar
                      value={progress}
                      maxValue={100}
                      styles={buildStyles({
                        pathColor: `#FF9C26`,
                        trailColor: 'transparent',
                        strokeLinecap: 'butt',
                      })}
                    />
                  </div>
                )}

                {item.badge && (
                  <img
                    src={item.badge.medium.url}
                    alt="Challenge icon"
                    className="w-16 h-16 object-contain rounded-full border border-white border-solid"
                  />
                )}
              </div>
              <div className="flex-1 leading-none text-sm">
                <div className="text-white mt-2 text-center">{item.challenge}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return <>{loading ? renderLoading() : renderList()}</>;
}
