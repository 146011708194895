// import Nav from 'components/nav';
import UserInfo from 'components/user-info';
import { AuthContext } from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { RiCloseFill } from 'react-icons/ri';
import ReactModal from 'react-modal';
import { VERSION } from 'utils/constants';

export function NavMobile({ ...props }) {
  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

  const { signOut } = useContext(AuthContext);

  ReactModal.setAppElement('#modal');

  const [modalIsOpen, setIsOpen] = useState(false);

  // function openModal() {
  // 	setIsOpen(true);
  // }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const onOpenMdal = () => {
      setIsOpen(true);
    };
    document.addEventListener('openModal', onOpenMdal);
    return () => {
      document.removeEventListener('openModal', onOpenMdal);
    };
  }, []);

  return (
    <div className="relative z-30">
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        portalClassName=""
        overlayClassName="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center"
        contentElement={() => (
          <div className="flex flex-col relative w-full h-full">
            <div className="pt-5 pr-5 flex items-center justify-end text-white">
              CLOSE <RiCloseFill size={28} />
            </div>
            <div className="overflow-y-scroll h-full">
              {/* <NavBar {...props} /> */}
              <div className="p-5 space-y-5">
                <div className="bg-white rounded-xl flex flex-row justify-between items-center">
                  <UserInfo />
                  <button
                    onClick={() => signOut()}
                    type="button"
                    className="mr-2 w-10 h-10 justify-center flex items-center text-red-600 opacity-50"
                  >
                    <BiLogOut size={24} />
                  </button>
                </div>
                {/* <Nav /> */}
								<div className='text-center text-xs text-white'>{VERSION}</div>
                <div className='text-center text-sm py-2 rounded-xl'><a className='hover:text-white visited:text-white text-white block' href='https://hp.sharepoint.com/sites/ww/GLA/Pages/policy-global-employee-data.aspx' target="_blank" rel='noreferrer'>HP Global Employee Privacy Policy &amp; Notice</a></div>
                {/* <RaceButton /> */}
                {/* <QuestListBox /> */}
                {/* <TeamBox /> */}
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
}
