
import { TOKEN_COOKIE } from '../utils/constants'

export function createTokenCookies (token: string, refreshToken: string) {
  // setCookie(undefined, TOKEN_COOKIE, token, {
  //   maxAge: COOKIE_EXPIRATION_TIME,
  //   path: '/',
  //   sameSite : 'None',
  //   secure : true
  // })

  localStorage.setItem(TOKEN_COOKIE,token);

  // setCookie(undefined, REFRESH_TOKEN_COOKIE, refreshToken, {
  //   maxAge: COOKIE_EXPIRATION_TIME,
  //   path: '/'
  // })
}

export function removeTokenCookies () {
  // destroyCookie(undefined, TOKEN_COOKIE)
  localStorage.clear();
  // destroyCookie(null, REFRESH_TOKEN_COOKIE)
}

export function getToken () {
  // const cookies = parseCookies()
  // return cookies[TOKEN_COOKIE]
  try{
    return localStorage.getItem(TOKEN_COOKIE) || "";
  }
  catch(e){
    return "";
  }
}

export function getRefreshToken () {
  // const cookies = parseCookies()
	// return cookies[TOKEN_COOKIE]
  // return cookies[REFRESH_TOKEN_COOKIE]
  try{
    return localStorage.getItem(TOKEN_COOKIE) || "";
  }
  catch(e){
    return "";
  }
}
