/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useAuth } from 'context/AuthContext';

export function Logout() {
  const { signOut } = useAuth();
  // const navigate = useNavigate();

  useEffect(() => {
    signOut();
    console.log("logout");
  }, []);

  return null;
}
