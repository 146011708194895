import { useEffect, useState } from 'react';
import character0 from '../../assets/img/character.png';
import character1 from '../../assets/img/character-2.png';

interface CharacterProps {
  random?: boolean;
  pose?: number;
}

const images = [character0, character1];

export function Character(props: CharacterProps) {
  const { pose, random } = props;

  const [current, setCurrent] = useState<any>();

  useEffect(() => {
    try {
      if (random) {
        const index = Math.round(Math.random() * (images.length - 1));
        setCurrent(images[index]);
      } else if (pose) {
        setCurrent(images[pose]);
      }
    } catch (e) {}
  }, [random, pose]);

  if (!current) return null;

  return <img src={current} alt="Character" className="object-cover" />;
}
