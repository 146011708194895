import Button from 'components/ui/button';
import { Uploader } from 'rsuite';

// import { AuthContext } from 'context/AuthContext';
import { ModalContext } from 'context/ModalContext';
import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { actionLog } from 'services/actionlog.service';
import { inputClass } from 'styles';

export function SelfReportModal() {
  const modalID = 'selfreport';

  const [modalIsOpen, setIsOpen] = useState(false);
  // const {user} = useContext(AuthContext);
  const { openModal, modalData, closeModal } = useContext(ModalContext);

  const [challenge, setChallenge] = useState<any>();
  const [details, setDetails] = useState('');

  function afterOpenModal() {}

  async function handleOnClick() {
    try {
      if (!challenge) return;
      console.log(challenge);
      const actionId = challenge.rules[0].actionId;
      const res = await actionLog(actionId, {
        details: details,
        content_id: challenge.extra.content_id
      });

      // await registerWowCheckin({
      // 	player: user?._id,
      // 	details,
      // 	date : new Date().getTime()
      // });

      if (res.achievements) {
        const hasAchievement = res.achievements.find((achievement: any) => achievement.type === 1);
        if (!!hasAchievement) {
          window.postMessage({ type: 'achievement', value: { name: challenge.challenge } }, '*');
        }
      }

      closeModal();
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    setIsOpen(challenge && openModal === modalID);
  }, [openModal, challenge]);

  useEffect(() => {
    if (modalData) {
      setChallenge(modalData);
    }
  }, [modalData]);

  ReactModal.setAppElement('#modal');

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        contentLabel="Example Modal"
        portalClassName=""
        shouldCloseOnEsc
        overlayClassName="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div className="flex flex-col md:w-1/2 bg-white p-5 rounded-xl ">
            <div className="flex fl justify-between items-start relative">
              <div className="absolute -top-12">
                <img src={challenge.badgeUrl} alt="wow-checkin" className="w-2/3" />
              </div>
              <Button className="absolute top-0 right-0" onClick={() => closeModal()}>
                CLOSE
              </Button>
            </div>

            <div className="text-center relative my-5">
              <p className="text-2xl font-bold text-blue-500">{challenge.challenge}</p>
              <p className="text-xl">{challenge.description}</p>
            </div>

            <div className="flex-1 overflow-auto space-y-2">
            <label className='font-lg font-bold'>Tell us a bit more about it</label>
              <textarea className={inputClass} onChange={(e) => setDetails(e.target.value)}></textarea>
            </div>

            {challenge.extra?.upload && (
              <div className="space-y-2">
                <label className='font-lg font-bold'>Send an attachment</label>
                <Uploader autoUpload={false} accept="image/jpg,image/jpeg,image/png" multiple={false} listType={"picture-text"} action="//jsonplaceholder.typicode.com/posts/" draggable>
                  <div
                    style={{
                      height: 60,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#f4f4f4',
                    }}
                  >
                    <span>Click or Drag files to this area to upload</span>
                  </div>
                </Uploader>
              </div>
            )}

            <Button className="m-5" onClick={handleOnClick}>
              REGISTER CHECK-IN
            </Button>
          </div>
        )}
      />
    </>
  );
}
