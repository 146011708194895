/* eslint-disable react-hooks/exhaustive-deps */
import ArticlePlaceholder from 'components/ui/article-placeholder';
import { useCallback, useEffect, useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

interface LeaderboardProps {
  data?: any[];
  leaders?: any[];
  userPosition?: any[];
  onSelect: (item: any) => void;
}

export default function Leaderboard(props: LeaderboardProps) {
  const [currentCompetition, setCurrentCompetition] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { data, leaders, userPosition, onSelect } = props;

  const handleSelect = useCallback(
    (item: any) => {
      if (onSelect) {
        onSelect(item);
        setCurrentCompetition(item);
      }
    },
    [onSelect]
  );

  function handlePaginate(index: number) {
    if (!data) return;
    let nextPage = currentIndex + index;
    if (nextPage < 0) {
      setCurrentIndex(data.length - 1);
    } else if (nextPage >= data.length) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(nextPage);
    }
  }

  useEffect(() => {
    if (data && data.length) {
      handleSelect(data[currentIndex]);
    }
  }, [currentIndex, handleSelect]);

  // useEffect(() => {
  //   if (data && data.length && !currentCompetition) {
  //     handleSelect(data[0]);
  //   }
  // }, [data, currentCompetition, handleSelect]);

  function renderList() {
    return (
      <>
        {data && currentCompetition && (
          <div
            // onClick={() => handleSelect(item)}
            className={`border border-green-custom bg-grey-dark flex flex-row items-center justify-between text-white py-2 px-3 rounded-lg`}
          >
            {data.length > 1 && (
              <div
                onClick={() => handlePaginate(-1)}
                className="cursor-pointer rounded-full hover:bg-white hover:bg-opacity-10"
              >
                <BiChevronLeft size={24} className="text-orange-500" />
              </div>
            )}
            <div className="flex-1 items-center flex flex-col">
              <h2 className="text-lg font-bold text-white">{currentCompetition.title}</h2>
              {currentCompetition.description && <p className="leading-tight">{currentCompetition.description}</p>}
              {/* <p className="text-xs border rounded-full text-center font-orange-700">
                  Ends in {new Date(item.period.endDate).toLocaleDateString()}
                </p> */}
            </div>
            {data.length > 1 && (
              <div
                onClick={() => handlePaginate(1)}
                className="cursor-pointer rounded-full hover:bg-white hover:bg-opacity-10"
              >
                <BiChevronRight size={24} className="text-orange-500" />
              </div>
            )}
          </div>
        )}
        {!leaders && (
          <div className="flex-1 mt-2 space-y-2">
            <ArticlePlaceholder />
          </div>
        )}
      </>
    );
  }

  function renderDetail() {
    return (
      <div className="flex-1 text-white space-y-3">
        {/* <div>
          <button
            onClick={() => {
              setCurrentCompetition(undefined);
            }}
            className="w-full border rounded-lg p-3"
          >
            BACK TO THE LIST
          </button>
        </div> */}
        <div className={`space-y-2 scroll-wrapper h-[600px] overflow-y-auto ${leaders && leaders?.length > 10 && 'pr-2'}`}>
          {/* <h2 className="text-xl font-bold">
            <em>{currentCompetition.title}</em>
          </h2> */}
          <div className="space-y-2">
          {userPosition?.map((leader: any) => (
              <div
                key={leader.player}
                className="border border-green-custom bg-green-custom rounded-lg flex flex-row items-center justify-between py-2 pl-2 pr-3 space-x-3"
              >
                <div className="text-center font-bold px-1">{leader.position}</div>
                <div className="font-bold flex flex-row items-center justify-center w-9 h-9 rounded-full bg-orange-500 text-white">
                  {!!leader?.image && (
                    <img
                      id="avatar"
                      src={leader?.image}
                      alt=""
                      className="w-full h-full rounded-full overflow-hidden"
                    />
                  )}

                  {!leader?.image && <div className="text-xl">{leader.name && leader.name.length > 0 ? leader.name.substr(0, 1) : '-'}</div>}
                </div>
                <div className="text-white flex-1 leading-none">
                  <div className="font-bold">{leader.name ? leader.name : 'No Name'}</div>
                  {leader.team && (
                    <div>
                      <small className="text-orange-500">{leader.team}</small>
                    </div>
                  )}
                </div>
                <div className="font-bold text-lg text-white">{leader.total}</div>
              </div>
            ))}

            {leaders?.map((leader: any) => (
              <div
                key={leader.player}
                className="bg-grey-dark rounded-lg flex flex-row items-center justify-between py-2 pl-2 pr-3 space-x-3"
              >
                <div className="text-center font-bold px-1">{leader.position}</div>
                <div className="font-bold flex flex-row items-center justify-center w-9 h-9 rounded-full bg-orange-500 text-white">
                  {!!leader?.image && (
                    <img
                      id="avatar"
                      src={leader?.image}
                      alt=""
                      className="w-full h-full rounded-full overflow-hidden"
                    />
                  )}

                  {!leader?.image && <div className="text-xl">{leader.name && leader.name.length > 0 ? leader.name.substr(0, 1) : '-'}</div>}
                </div>
                <div className="text-white flex-1 leading-none">
                  <div className="font-bold">{leader.name ? leader.name : 'No Name'}</div>
                  {leader.team && (
                    <div>
                      <small className="text-orange-500">{leader.team}</small>
                    </div>
                  )}
                </div>
                <div className="font-bold text-lg text-green-custom">{leader.total}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {data && data.length === 0 && (
        <h2 className="text-xl p-3 text-white text-center">
          <em>There is no active competition</em>
        </h2>
      )}

      <div>{renderList()}</div>

      {!!leaders && <div>{renderDetail()}</div>}
    </div>
  );
}
