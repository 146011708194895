import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import {
	createContext,
	ReactNode,
	useContext,
} from "react";
import { toast } from "react-toastify";
import { getUserProfile } from "services/profile.service";
import { IUserCard } from "types/networking";
import useEvent from "utils/use-event";

interface NetworkingContextData {
	// connect: () => void;
	// disconnect: () => void;
	// sendMessage: (message:any) => void;
}

interface NetworkingContextProps {
	children: ReactNode;
}

export const NetworkingContext = createContext({} as NetworkingContextData);

function NetworkingProvider({ children }: NetworkingContextProps) {
	const { showModal } = useModal();
	const { refreshUser, signOut } = useAuth();

	// const websocketMessage = useEvent("websocket-message", handleMessage);
	useEvent("websocket-message", handleMessage);

	async function handleMessage({ detail }: CustomEvent) {
		try {
			if (detail.type === "connect") {
				const sender = detail.from;
				const senderData = await getUserProfile(sender);
				if(!senderData){
					signOut();
				}
				refreshUser();
				showModal("invite", { invite: { ...senderData, request: detail.request } });
			} else if (detail.type === "connect-accept") {
				const sender = detail.from;
				const senderData = (await getUserProfile(sender)) as IUserCard;
				if(!senderData){
					signOut();
				}
				refreshUser();
				toast(
					`🚀 ${
						senderData ? `${senderData.name} is now your friend!` : "A WOWler has accepted your friend request"}`,
					{
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);
			}
		} catch (e) {
			console.log(e);
		}
	}

	return (
		<NetworkingContext.Provider value={{}}>
			{children}
		</NetworkingContext.Provider>
	);
}

function useNetworking() {
	const context = useContext(NetworkingContext);
	if (context === undefined) {
		throw new Error("useNetworking must be used within a NetworkingProvider");
	}
	return context;
}

export { NetworkingProvider, useNetworking };
