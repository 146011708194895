import { api } from "./api"

export async function registerWowCheckin (payload:any) {
	const {data} = await api.post(`database/wow_log__c`,payload);
	return data;
}

export async function registerInspirationalMessage (payload:any) {
	const {data} = await api.post(`database/inspirational_messages__c`,payload);
	return data;
}

export async function fetchInspirationalMessages () {
  const payload:any = [];
	const {data} = await api.post(`database/inspirational_messages__c/aggregate`,payload);
	return data
}

export async function clearNotifications () {
	const {data} = await api.get('notification?player=me&scope=private&max_results=1&delete=true')
	return data
}
