import { NavLink } from "react-router-dom";

export function TabBarItem({ ...props }) {
	const { id, target, label, children } = props;
	return (
		<NavLink
			id={id}
			to={target}
			className={({ isActive }) =>
				`flex-1 flex flex-col items-center justify-center text-white space-y-1 ${isActive && 'visited:text-white text-white bg-gold'}`
			}
		>
			<div className="text-xl">{children}</div>
			<div className="text-xs">{label}</div>
		</NavLink>
	);
}
