import { api } from "./api";

export async function getBadges(player: string) {
  const { data } = await api.get(`challenge`, {
    params: {
      orderby: "extra.order",
      reverse: true,
      available: true,
      player,
    },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data.filter((item) => item.techniques.includes("GT02"));
  }
}
