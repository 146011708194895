import { api } from './api';

export async function getLeaderboardLeaders(leaderboardId: string, limit: number): Promise<any[]> {
  const payload = [
    {
      $sort: {
        position: 1,
      },
    },
    {
      $limit: limit,
    },
  ];
  const { data } = await api.post(`database/${leaderboardId}/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getPlayerPosition(user: string): Promise<any[]> {
  const payload = [{"$match":{ "_id": user }}];

  const { data } = await api.post(`database/cache_ranking_week__c/aggregate?strict=true`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getLeaderboards(): Promise<any[]> {
  return [
    {
      title: 'Weekly Leaders',
      description: 'User with more points in the week',
      collection: 'cache_ranking_week__c',
      limit: 100,
    },
    {
      title: 'GammaX Top Users',
      description: 'Global users with more points',
      collection: 'cache_ranking_year__c',
      limit: 10,
    },
  ];
  // const { data } = await api.get(
  // 	`leaderboard`
  // );
  // return data;
}

export async function getCompetitions(): Promise<any[]> {
  const { data } = await api.get(`competition`);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getCompetitionLeaders(competitionId: string): Promise<any[]> {
  const payload = [
    { $lookup: { from: 'player', localField: 'player', foreignField: '_id', as: 'p' } },
    { $unwind: '$p' },
    {
      $project: { name: '$p.name', wowler: '$p.extra.wowler', team: '$p.extra.team', player: 1, total: 1, position: 1 },
    },
    { $sort: { position: 1 } },
  ];
  const { data } = await api.post(`competition/leader/aggregate?id=${competitionId}`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}
