import { ProgressBar } from "components/progress-bar";
import { useAuth } from "context/AuthContext";
import { BiLogOut, BiUser } from "react-icons/bi";
import { useNavigate } from "react-router";

import "./style.scss";

export default function UserInfo() {
  const navigate = useNavigate();
  const {user , signOut} = useAuth();
  const onOpenProfile = () => {
    navigate("/profile");
  };

  function handleSignout(){
    if(window.parent){
      window.parent.location.href = "https://gammax.exchange/user-logout";
    }
    else{
      signOut();
    }
  }

  return (
    <>
      <div className="p-1 flex items-center space-x-2 py-5 md:min-w-[335px] ">
        <div className="flex flex-col items-center">
          <div className={`ambassador ${user?.extra?.ambassador ? "active" : ""} flex justify-center items-center`}>
            <div className="relative bg-white avatar w-[64px] h-[64px] bg-gray-100 border rounded-full">
              <button
                onClick={onOpenProfile}
                className="w-full h-full flex items-center justify-center"
              >
                {!!user?.image?.medium?.url && (
                  <img
                    id="avatar"
                    src={user?.image.medium.url}
                    alt=""
                    className="w-full h-full rounded-full overflow-hidden"
                  />
                )}
                {!user?.image?.medium?.url && <BiUser size={52} className="text-gray-500" />}
              </button>
            </div>
          </div>
          {user?.extra?.ambassador && <p className="text-white text-sm text-yellow-500 font-bold">Ambassador</p>}
        </div>

        <div className="flex-1 flex flex-col">
          <div className="flex items-start justify-between mb-2">
            <h2 className="text-lg font-bold text-white leading-none">
              {user?.name || user?._id}
            </h2>

            <div className="flex flex-col items-end">
              <span className="text-gold font-light">Points:</span>
              <p className="text-white text-xl font-bold drop-shadow-lg leading-none">
                {user?.point_categories.points}{" "}
              </p>
            </div>
          </div>

          <ProgressBar />
        </div>

        <div className="hidden md:flex space-x-3">
          <button onClick={() => handleSignout()} type="button" className="text-red-600 opacity-50">
            <BiLogOut size={24} />
          </button>
        </div>
      </div>
    </>
  );
}
