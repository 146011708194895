import { api } from './api';

export async function getVirtualGoods() {
  try {
    const { data } = await api.get('virtualgoods/item?player=me&orderby=extra.order&reverse=false');
    return data;
  } catch (error) {
    return error;
  }
}

export async function getVirtualGood(_id: string) {
  try {
    const { data } = await api.get(`virtualgoods/item/${_id}`);
    return data;
  } catch (error) {
    return error;
  }
}

export async function purchaseItem(item: string, player: string) {
  try {
    const { data } = await api.post('virtualgoods/purchase', {
      item,
      player,
    });
    return data;
  } catch (error) {
    return error;
  }
}
