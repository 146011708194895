/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';

import Button from 'components/ui/button';
import { getChallengeCategory, IChallengeCategory } from 'services/challenges.service';

import './style.scss';

interface FilterProps {
  onChange: (item: IChallengeCategory) => void;
}

export function Filter(props: FilterProps) {
  const { onChange } = props;

  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);
  const [data, setData] = useState<IChallengeCategory[]>();
  const [activeCategory, setActiveCategory] = useState<IChallengeCategory>();

  function handleClick(category: IChallengeCategory) {
    setActiveCategory(category);
    onChange(category);
  }

  useEffect(() => {
    async function loadData() {
      try {
        const result = await getChallengeCategory();
        setData(result);
        handleClick(result[0]);
      } catch (e) {}
    }

    loadData();
  }, []);

  return (
    <div {...events} ref={ref} className="filter-item flex p-2 space-x-4 overflow-x-auto mb-4">
      {data &&
        data.map((item) => (
          <Button
            key={item._id}
            onClick={() => handleClick(item)}
            className="whitespace-nowrap"
            color={activeCategory?._id === item._id ? 'primary' : 'outline'}
          >
            {item.title}
          </Button>
        ))}
    </div>
  );
}
