import Button from 'components/ui/button';
import { AuthContext } from 'context/AuthContext';
import { ModalContext } from 'context/ModalContext';
import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { actionLog } from 'services/actionlog.service';
import { registerWowCheckin } from 'services/checkin.service';
import { inputClass } from 'styles';

const wowCheckin = require('assets/img/btn-wow-checkin.png');

export function CheckinModal() {
  const modalID = 'checkin';

  const [modalIsOpen, setIsOpen] = useState(false);
	const {user} = useContext(AuthContext);
  const { openModal, closeModal } = useContext(ModalContext);

  // const [challenge, setChallenge] = useState<any>();
  const [details, setDetails] = useState('');
  const [caseId, setCaseId] = useState('');

  function afterOpenModal() {}

  async function handleOnClick() {
    try {

      if(!details || !caseId){
        return;
      }

      const res = await actionLog('interaction', {
        content_id: "wow",
        type: 'checkin',
        details: details,
        case_id: caseId
      });

			await registerWowCheckin({
				player: user?._id,
				details,
        case_id: caseId,
				date : new Date().getTime()
			});

      if (res.achievements) {
        const hasAchievement = res.achievements.find((achievement: any) => achievement.type === 1);
        if (!!hasAchievement) {
          window.postMessage({ type: 'achievement', value: { name: "WOW! Check-in" } }, '*');
        }
      }

			closeModal();
			
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    setIsOpen(openModal === modalID);
    setDetails('');
    setCaseId('');
  }, [openModal]);

  ReactModal.setAppElement('#modal');

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        contentLabel="Example Modal"
        portalClassName=""
        shouldCloseOnEsc
        overlayClassName="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div className="flex flex-col md:w-1/2 bg-white p-5 rounded-xl ">
            <div className="flex fl justify-between items-start">
             <div className='relative -top-12'>
							
              <img src={wowCheckin} alt="wow-checkin" className="w-1/2" />

						 </div>
              <Button onClick={() => closeModal()}>CLOSE</Button>
            </div>

            <p className='text-2xl font-bold text-center relative -top-5'>Well done! Let us know how you did that and add the CaseID.</p>

            <div className="flex-1 overflow-auto space-y-2">

            <p className='font-bold'>CaseID</p>
              <input className={inputClass} onChange={(e) => setCaseId(e.target.value)}></input>

<p className='font-bold'>Details</p>
              <textarea rows={3} className={inputClass} onChange={(e) => setDetails(e.target.value)}></textarea>
            </div>
						
						<Button className={`m-5 ${!details||!caseId ? 'opacity-30' : ''}`} disabled={!details||!caseId} onClick={handleOnClick}>REGISTER CHECK-IN</Button>
          </div>
        )}
      />
    </>
  );
}
