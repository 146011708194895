/* eslint-disable react-hooks/exhaustive-deps */
// import {  useEffect,  } from 'react';

import { H1 } from 'components/typography';
import Box from 'components/ui/box';
import Button from 'components/ui/button';
import { TbRocket } from 'react-icons/tb';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

// import { AuthContext } from 'context/AuthContext';

// import Leaderboard from 'components/leaderboard';
// import { getCompetitionLeaders, getCompetitions } from 'services/leaderboard.service';

export function League() {
  // const { user } = useContext(AuthContext);

  // const [loadingLeaders, setLoadingLeaders] = useState(true);

  // const [competitionList, setCompetitionList] = useState<any[]>();
  // const [currentCompetition, setCurrentCompetition] = useState<any>();
  // const [currentLeaders, setCurrentLeaders] = useState<any>();

  // const [leadersWowlers, setLeaderWowlers] = useState<any[]>();

  // useEffect(() => {
  //   async function loadData() {
  //     setLoadingLeaders(true);
  //     setCurrentLeaders(undefined);
  //     const leadersResult = await getCompetitionLeaders(currentCompetition._id);
  //     const top3leaders = leadersResult.filter((item: any) => item.position < 4);
  //     setCurrentLeaders(leadersResult);
  //     setLeaderWowlers(top3leaders);
  //     setLoadingLeaders(false);
  //   }

  //   if (currentCompetition) {
  //     loadData();
  //   }
  // }, [currentCompetition]);

  // useEffect(() => {
  //   async function loadData() {
  //     try {
  //       setLoadingLeaders(true);
  //       const competitionResults = await getCompetitions();
  //       setCompetitionList(competitionResults);

  //       if (competitionResults && competitionResults.length > 0) {
  //         setCurrentCompetition(competitionResults[0]);
  //       }
  //     } catch (e) {}
  //   }

  //   if (user) {
  //     loadData();
  //   }
  // }, [user]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 819px)' });

  // if (!user) return null;

  return (
    <div className="md:container mx-auto">
      <div className="hidden md:block absolute top-10 left-10">
        <Link to="/" children={<Button>BACK</Button>} />
      </div>

      <Box>
        <div className="flex flex-row px-1 pb-3 md:py-3 space-x-5 items-center">
          <div className="w-16 h-16 flex-0 md:w-24 md:h-24 border rounded-full bg-black text-white flex flex-row items-center justify-center">
            <TbRocket size={isTabletOrMobile ? 32 : 64} />
          </div>

          <div className="flex-1 leading-tight">
            <H1>Ranking</H1>
            <p>Check-out your position in the current competitions</p>
          </div>
        </div>

        <div className="mt-10"></div>
      </Box>
    </div>
  );
}
