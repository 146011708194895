import { AchievementModal } from 'components/achievement-modal';
import { CheckinModal } from 'components/checkin-modal';
import { FeedbackModal } from 'components/feedback-modal';
import Header from 'components/header';
import { InspirationalModal } from 'components/inspirational-modal';
import { NavMobile } from 'components/nav-mobile';
import { SelfReportModal } from 'components/selfreport-modal';
// import Sidebar from 'components/sidebar';
import { TabBar } from 'components/tabbar';
import { ReactNode, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { validateUserPermissions } from '../utils/validateUserPermissions';

interface IPrivateRoute {
  permissions?: string[];
  roles?: string[];
  redirectTo?: string;
  children: ReactNode;
}

export function PrivateRoute({ permissions, roles, redirectTo = '/login', children }: IPrivateRoute) {
  const { isAuthenticated, user } = useContext(AuthContext);

  const { hasAllPermissions } = validateUserPermissions({
    user,
    permissions,
    roles,
  });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 819px)" });

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }

  if (!hasAllPermissions) {
    return <Navigate to="/" />;
  }

  return (
    <>
    {isTabletOrMobile && <NavMobile />}
    <div className="">
      <Header />
      <>{children}</>
      {/* <Sidebar /> */}
      <TabBar />
      <CheckinModal />
      <SelfReportModal />
      <InspirationalModal />
      <AchievementModal />
      <FeedbackModal />
    </div>
    </>
  );
}
