import { toast } from 'react-toastify';
import { ActionLog } from 'types/funifier';
import { api } from './api';
import { getChallengeById } from './challenges.service';

// import { Attributes } from '../models/ActionLog.types';
// import { storageGet } from './storage';

// const useQuery = () => {
//   return new URLSearchParams(window.location.search);
// };

export const actionLog: any = (actionId: string, attributes?: any, notificationType = 'modal') => {
  return new Promise((resolve, reject) => {
    // const global = storageGet('global'),

    const _attributes = Object.assign({}, attributes);

    // query = useQuery();
    // _attributes.time = new Date().getTime();

    const payload = {
      userId: 'me',
      actionId: actionId,
      time: new Date().getTime(),
      attributes: _attributes,
    };

    api
      .post('/action/log?async=false', payload)
      .then((response) => {
        if (response.data.achievements.length) {
          const event = new Event('reload:user');
          document.dispatchEvent(event);

          try {
            if (response.data.achievements) {
              const hasAchievement = response.data.achievements.find((achievement: any) => achievement.type === 1);

              if (!!hasAchievement) {
                if (notificationType === 'modal') {
                  getChallengeById(hasAchievement.item).then(challengeData => {
                    window.postMessage({ type: 'achievement', value: challengeData }, '*');
                  });

                } else {
                  toast(`🚀 You've got new achievements!`, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              }
            }
          } catch (err) {
            console.log(err);
          }
        }

        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const actionLogBulk = (actionId: string, data: any[]) => {
  return new Promise((resolve, reject) => {
    const payload: ActionLog[] = data.map((action) => {
      return {
        userId: 'me',
        actionId: actionId,
        time: action.date || new Date().getTime(),
        attributes: {
          ...action,
        },
      };
    });

    // console.log(payload);

    // return;

    api
      .post('/action/log/bulk?async=false', payload)
      .then((response) => {
        const event = new Event('reload:user');
        document.dispatchEvent(event);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export async function getActions(actionId: string): Promise<ActionLog[]> {
  try {
    const { data } = await api.get(`action/log`, {
      params: { player: 'me', action: actionId, orderby: 'time', reverse: true },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export const actionLogQuest = (operation: string, player: string, item: string) => {
  return new Promise((resolve, reject) => {
    const payload = {
      operation: operation,
      item: item,
      player: player,
    };

    api
      .put('/database/social_quests_log__c', payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
