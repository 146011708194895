/**
 * Composing <Route> in React Router v6
 * https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 *
 * Upgrading from v5
 * https://reactrouter.com/docs/en/v6/upgrading/v5
 */
import { Routes, Route } from 'react-router-dom';

import { Dashboard } from 'pages/Dashboard';
import { Login } from 'pages/Login';
import { NotFound } from 'pages/NotFound';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { Shop } from 'pages/Shop';
import { League } from 'pages/League';
import { Profile } from 'pages/Profile';
import ShopDetail from 'pages/Shop/Detail';
import { News } from 'pages/News';
import { Logout } from 'pages/Logout';

export const RouteList = () => (
  <Routes>

    <Route
      path="/logout"
      element={
        <Logout />
      }
    />

    <Route
      path="/"
      element={
        <PrivateRoute redirectTo="/login">
          <Dashboard />
        </PrivateRoute>
      }
    />

    <Route
      path="/profile"
      element={
        <PrivateRoute redirectTo="/login">
          <Profile />
        </PrivateRoute>
      }
    />
{/* 
    <Route
      path="/challenges"
      element={
        <PrivateRoute redirectTo="/login">
          <Challenges />
        </PrivateRoute>
      }
    /> */}

    <Route
      path="/news"
      element={
        <PrivateRoute redirectTo="/login">
          <News />
        </PrivateRoute>
      }
    />

    <Route
      path="/ranking"
      element={
        <PrivateRoute redirectTo="/login">
          <League />
        </PrivateRoute>
      }
    />

    <Route
      path="/store"
      element={
        <PrivateRoute redirectTo="/login">
          <Shop />
        </PrivateRoute>
      }
    >
      <Route path=":id" element={<ShopDetail />} />
    </Route>

    <Route
      path="/login"
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />

    <Route path="*" element={<NotFound />} />
  </Routes>
);
