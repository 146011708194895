/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from 'context/AuthContext';

import './styles.scss';
import SubHeader from 'components/SubHeader';
import { ChallengeList } from 'components/challenge-list';
import { BadgesList } from 'components/badges-list';
import { Slide } from 'components/Slide';
import NewsList from 'components/news-list';

import UserInfo from 'components/user-info';
import { BiGift } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { PromoBanners } from 'components/promo-banners';
import { Character } from 'components/character';

import { LeaderboardList } from 'components/leaderboard-list';

export function Dashboard() {
  const { user } = useAuth();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 819px)' });

  if (!user) return null;

  return (
    <div className="bg-black -mt-10 pt-10 min-h-screen-full px-[19px] pb-20">
      <div className="md:hidden">
        <div className="my-2">
          <div className="font-bold container border border-green-custom rounded-xl p-2 text-center">
            {user?.extra?.affiliateCode ? (
              <span>
                Share this code to earn referral points:{' '}
                <a
                  href={`https://gammax.exchange/beta-testers/?referral=${user?.extra?.affiliateCode}`}
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-green-custom"
                >
                  {user?.extra?.affiliateCode}
                </a>
              </span>
            ) : (
              <span>You don't have a Affiliate Code. Please contact us.</span>
            )}
          </div>
        </div>

        <section>
          <SubHeader title="Badge•S" />
          <BadgesList />
        </section>

        <section>
          <SubHeader title="Mission•X" />
          <ChallengeList customStyle="space-y-3" />
        </section>

        {isTabletOrMobile && (
          <section>
            <SubHeader title="What's•Up" />
            <Slide />
          </section>
        )}

        <section>
          <SubHeader title="New•S" />
          <NewsList />
        </section>

        <section>
          <SubHeader title="Ranking•S" />
          <LeaderboardList />
        </section>
      </div>

      {!isTabletOrMobile && <PromoBanners />}

      {/* <div className='container px-14 my-5'>
        <div className='font-bold container border border-green-custom rounded-xl p-2 text-center'>
        {user?.extra?.affiliateCode ? <span>Share this code to earn referral points: <a href={`https://gammax.exchange/beta-testers/?referral=${user?.extra?.affiliateCode}`} target="_blank" rel="noreferrer" className='underline text-green-custom'>{user?.extra?.affiliateCode}</a></span> : <span>You don't have a Affiliate Code. Please contact us.</span>}
        </div>
      </div> */}

      <div className="hidden pt-5 md:grid grid-cols-3 container gap-x-14">
        <div>
          {!isTabletOrMobile && (
            <section>
              <UserInfo />
              <div className="my-2">
                <div className="font-bold container border border-green-custom rounded-xl p-2 text-center">
                  {user?.extra?.affiliateCode ? (
                    <span>
                      Share this code to earn referral points:{' '}
                      <a
                        href={`https://gammax.exchange/beta-tester/?referral=${user?.extra?.affiliateCode}`}
                        target="_blank"
                        rel="noreferrer"
                        className="underline text-green-custom"
                      >
                       https://gammax.exchange/beta-tester/?referral={user?.extra?.affiliateCode}
                      </a>
                    </span>
                  ) : (
                    <span>
                      You don't have an affiliate code.
                      <br />
                      Please contact us.
                    </span>
                  )}
                </div>
              </div>
            </section>
          )}

          <section>
            <SubHeader title="Badge•S" />
            <BadgesList />
          </section>

          <section>
            <SubHeader title="Ranking•S" />
            <LeaderboardList />
          </section>
        </div>

        <div>
          <section>
            <SubHeader title="Mission•X" />
            <ChallengeList customStyle="space-y-3" />
          </section>
        </div>

        <div>
          <section>
            <SubHeader title="New•S" url="/news" />
            <NewsList />
          </section>

          <section>
            <SubHeader title="Stor•E" url="/store" />
            <Link to="/store">
              <div className="flex flex-row space-x-3 items-center">
                <div className="rounded-full border border-white p-3">
                  <BiGift size={32} />
                </div>
                <h1 className="text-lg">Check out cool rewards to redeem!</h1>
              </div>
            </Link>
          </section>

          <div className="-mx-10">
            <Character random />
          </div>
        </div>
      </div>
    </div>
  );
}
