import ArticlePlaceholder from 'components/ui/article-placeholder';
import { AuthContext } from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { getChallenges, IChallenge, IChallengeCategory } from 'services/challenges.service';
import { Filter } from './components/filter';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import { BiLink } from 'react-icons/bi';

export function ChallengeList({ customStyle }: { customStyle?: string }) {
  const [loading, setLoading] = useState(true);
  const [challenges, setChallenges] = useState<any[]>();
  const { user } = useContext(AuthContext);
  const [currentCategory, setCurrentCategory] = useState<IChallengeCategory>();

  function isQuestCompleted(challengeId: string) {
    if (!user) return 0;
    return user.challenges[challengeId] || 0;
  }

  function getQuestProgress(challengeId: string) {
    if (!user) return false;
    try {
      const challengeProgress = user?.challenge_progress?.find(
        (item) => item.challenge === challengeId
      )?.percent_completed;
      return challengeProgress;
    } catch (e) {
      return 0;
    }
  }

  // async function handleCheckIn(challenge: any) {
  //   showModal("selfreport", challenge);
  // }

  useEffect(() => {
    if (!user || !currentCategory) return;
    setLoading(true);
    async function loadData() {
      const data = await getChallenges(user!._id);
      const preparedData = data.filter((item: IChallenge) => {
        try {
          if (currentCategory) {
            if (item.extra && item.extra.category) {
              return item.extra.category.toLowerCase().indexOf(currentCategory._id.toLowerCase()) > -1;
            }
            return false;
          } else {
            return true;
          }
        } catch (e) {
          return true;
        }
      });
      setChallenges(preparedData);
      setLoading(false);
    }

    loadData();
  }, [user, currentCategory]);

  function renderLoading() {
    return (
      <div className="space-y-2">
        <ArticlePlaceholder />
        <ArticlePlaceholder />
        <ArticlePlaceholder />
      </div>
    );
  }

  function renderList() {
    if (!challenges?.length) {
      return (
        <div className="text-center">
          <h1>There's no missions for this category</h1>
        </div>
      );
    }

    return challenges?.map((item) => {

      const completed = isQuestCompleted(item._id);
      const progress = getQuestProgress(item._id);

      return (
        <div
          key={item._id}
          className={`${
            completed > 0 ? 'border border-green-custom border-solid' : ''
          } flex bg-grey-dark rounded-2xl p-2 space-x-2 min-h-[60px]`}
        >
          <div>
            {completed > 0 && (
              <div className='absolute z-10'>
                <AiFillCheckCircle color="#20E0BE" size={45} />
              </div>
            )}

            {progress && progress > 0 && (
              <div className="h-[45px] w-[45px] font-bold absolute z-10">
                <CircularProgressbar
                  key={item._id}
                  value={progress}
                  text={`${progress}%`}
                  maxValue={100}
                  styles={buildStyles({
                    pathColor: `#FF9C26`,
                    textSize: '1.6rem',
                    textColor: '#fff',
                    trailColor: 'transparent',
                    strokeLinecap: 'butt',
                  })}
                />
              </div>
            )}

            {/* {!completed && !progress && (
              <div className="h-[45px] w-[45px] font-bold absolute z-10">
                <CircularProgressbar
                  key={item._id}
                  value={100}
                  text={`${0}%`}
                  maxValue={100}
                  styles={buildStyles({
                    pathColor: `#3366bb55`,
                    textSize: '1.6rem',
                    textColor: '#fff',
                    trailColor: 'transparent',
                    strokeLinecap: 'butt',
                  })}
                />
              </div>
            )} */}

            <div className="h-[45px] w-[45px] font-bold overflow-hidden rounded-full relative">
              {item.badge && <img src={item.badge.medium.url} alt="" className='w-full h-full object-cover opacity-40' />}
            </div>

          </div>

          <div className="flex flex-row items-center justify-between flex-1 leading-none text-sm">
            <div className="text-white flex-1 pr-2">
              <div className="font-bold">{item.challenge}</div>
              <div className="text-xs text-gray-200 leading-tight mt-1">{item.description}</div>
              {item.extra.link ? <div className='mt-1'><a href={item.extra.link} rel="noreferrer" target="_blank" className="flex flex-row items-center text-xs text-green-custom space-x-1 leading-tight">
                <BiLink /><span>Visit Link</span></a></div> : null }
            </div>

            <div className="flex flex-col items-end text-gold space-y-1">
              {item.points
                .filter((point: any) => point.operation === 0)
                .map((point: any, index: number) => (
                  <p className="m-0 p-0" key={`${item._id}-point-${index}`}>
                    <b>+{point.total}</b> <span className="text-xs">{point.category}</span>
                  </p>
                ))}
            </div>

            {/* <div className="ml-2">
              <MdArrowForwardIos color="white" size={20} />
            </div> */}
          </div>
        </div>
      );
    });
  }

  return (
    <>
      <Filter onChange={(item) => setCurrentCategory(item)} />
      {loading ? (
        renderLoading()
      ) : (
        <>
          <div className={customStyle}>{renderList()}</div>
        </>
      )}
    </>
  );
}
