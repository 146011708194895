/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';

import { AuthContext } from 'context/AuthContext';

import Box from 'components/ui/box';
import { H1 } from 'components/typography';
import { useMediaQuery } from 'react-responsive';
import { AiOutlineNotification } from 'react-icons/ai';
import NewsList from 'components/news-list';
import { Link } from 'react-router-dom';
import Button from 'components/ui/button';

export function News() {
  const { user } = useContext(AuthContext);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 819px)' });

  if (!user) return null;

  return (
    <div className="md:container mx-auto">
      <div className="hidden md:block absolute top-10 left-10">
        <Link to="/" children={<Button>BACK</Button>} />
      </div>

      <Box>
        <div className="flex flex-row px-1 pb-3 md:py-3 space-x-5 items-center">
          <div className="w-16 h-16 flex-0 md:w-24 md:h-24 border rounded-full bg-black text-white flex flex-row items-center justify-center">
            <AiOutlineNotification size={isTabletOrMobile ? 32 : 64} />
          </div>

          <div className="flex-1 leading-tight">
            <H1>News</H1>
            <p>Check-out what the news</p>
          </div>
        </div>

        <div className="mt-10">
          <NewsList />
        </div>
      </Box>
    </div>
  );
}
