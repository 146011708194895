import { api } from "./api";

export interface Small {
  url: string;
}

export interface Medium {
  url: string;
}

export interface Original {
  url: string;
}

export interface Image {
  small: Small;
  medium: Medium;
  original: Original;
}

export interface IBanners {
  _id: string;
  title: string;
  created: number;
  image: Image;
  link: string;
  updated: number;
}

export async function fetchBanners() {
  const { data } = await api.get<IBanners[]>(`database/banners__c`);
  return data;
}
