/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getToken } from 'utils/tokenCookies';

interface ChatContextData {
  // connect: () => void;
  // disconnect: () => void;
  sendMessage: (message: any) => void;
  fetchMessages?: () => void;
}

interface ChatContextProps {
  children: ReactNode;
}

export const ChatContext = createContext({} as ChatContextData);

function ChatProvider({ children }: ChatContextProps) {
  const [client, setClient] = useState<WebSocket>();

  function connect() {
    try {
      if (client) {
        client.close();
      }
      setTimeout(() => {
        const token = getToken();
        if (token) {
          const socketClient = new WebSocket(
            `wss://service2.funifier.com/ws/chat?authorization=${encodeURIComponent(token)}`
          );

          socketClient.addEventListener('error', (event) => {
            console.log('WebSocket error: ', event);
          });

          setClient(socketClient);
        }
      }, 1000);
    } catch (e) {
      console.log(e);
      disconnect();
    }
  }

  function disconnect() {
    if (client) {
      client.close();
    }
    setClient(undefined);
  }

  function onMessage(e: MessageEvent) {
    dispatchEvent(new CustomEvent('chat-message', { detail: JSON.parse(e.data) }));
  }

  function sendMessage(message: any) {
    if (!client) return;
    try {
      client.send(JSON.stringify(message));
    } catch (e) {
      connect();
      setTimeout(() => {
        sendMessage(message);
      }, 1000);
    }
  }

  // async function loadMessages() {
  //   try {
  //     // const data = await fetchMessages();
  //   } catch (e) {
  //     return [];
  //   }
  // }

  useEffect(() => {
    connect();
  }, []);

  useEffect(() => {
    if (client) {
      client.onmessage = onMessage;
    }
    return () => {
      if (client) {
        client.onmessage = null;
      }
    };
  }, [client]);

  return (
    <ChatContext.Provider
      value={{
        sendMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

function useChat() {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}

export { ChatProvider, useChat };
