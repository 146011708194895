/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { getToken } from "utils/tokenCookies";

interface WebsocketContextData {
	// connect: () => void;
	// disconnect: () => void;
	sendMessage: (message:any) => void;
}

interface WebsocketContextProps {
	children: ReactNode;
}

export const WebsocketContext = createContext({} as WebsocketContextData);

function WebsocketProvider({ children }: WebsocketContextProps) {
	const [client, setClient] = useState<any>();

	function connect() {
		try {
			if(client){
				client.close();
			};
			const token = getToken();
			if (token) {
				const socketClient = new WebSocket(
					`wss://service2.funifier.com/ws/networking?authorization=${encodeURIComponent(
						token
					)}`
				);
				setClient(socketClient);
			}
		} catch (e) {
			console.log(e);
			disconnect();
		}
	}

	function disconnect() {
		if (client) {
			client.close();
		}
		setClient(undefined);
	}

	function onMessage(e:MessageEvent) {
		dispatchEvent(new CustomEvent("websocket-message", { detail: JSON.parse(e.data) }));
	}

	function sendMessage(message:any) {
		if (!client) return;
		client.send(JSON.stringify(message));
	}

	useEffect(() => {
		connect();
	}, []);

	useEffect(() => {
		if (client) {
			client.onmessage = onMessage;
		}
		return () => {
			if (client) {
				client.onmessage = null;
			}
		}
	} ,[client]);

	return (
		<WebsocketContext.Provider
			value={{
				sendMessage,
			}}
		>
			{children}
		</WebsocketContext.Provider>
	);
}

function useWebsocket() {
  const context = useContext(WebsocketContext)
  if (context === undefined) {
    throw new Error('useWebsocket must be used within a WebsocketProvider')
  }
  return context
}

export {WebsocketProvider, useWebsocket}
