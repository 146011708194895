import { useEffect, useState } from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { fetchBanners } from 'services/banners.service';
import { IBanners } from 'services/banners.service';

export function PromoBanners() {
  const [loading, setLoading] = useState(true);
  const [banners, SetBanners] = useState<IBanners[]>();

  useEffect(() => {
    setLoading(true);
    async function loadData() {
      const data = await fetchBanners();
      SetBanners(data);

      setLoading(false);
    }

    loadData();
  }, []);

  function renderLoading() {
    return null;
  }

  function renderList() {
    return (
      <div className="container mx-auto flex flex-row items-center mt-10 space-x-10">
        {banners?.map((item) => (
          <div className="rounded-lg overflow-hidden flex-1 hover:ring-2 ring-green-custom relative" key={item._id}>
            <img alt={item.title} src={item.image.original.url} className="w-full h-36 pointer-events-none" />
            {item.link ? (
              <a
                href={item.link}
                target="_blank"
                rel="noreferrer"
                className="absolute top-0 left-0 z-20 w-full h-full"
              >
                <p className="text-white legend text-center leading-none font-bold z-10 absolute top-0 left-0 w-full h-full flex flex-row items-center">
                  {' '}
                </p>
              </a>
            ) : null}
          </div>
        ))}
      </div>
    );
  }

  return <>{loading ? renderLoading() : renderList()}</>;
}
