import { api } from "./api";

export interface Rule {
  actionId: string;
  position: number;
  operator: number;
  timeAmount: number;
  timeScale: number;
  outOfTime: boolean;
  everyAmount: number;
  everyScale: number;
  total: number;
}

export interface Small {
  url: string;
  size: number;
  width: number;
  height: number;
  depth: number;
}

export interface Medium {
  url: string;
  size: number;
  width: number;
  height: number;
  depth: number;
}

export interface Original {
  url: string;
  size: number;
  width: number;
  height: number;
  depth: number;
}

export interface Badge {
  small: Small;
  medium: Medium;
  original: Original;
}

export interface Point {
  total: number;
  category: string;
  operation: number;
}

export interface Extra {}

export interface I18n {}

export interface IChallenge {
  challenge: string;
  description: string;
  range: number;
  active: boolean;
  rules: Rule[];
  teamChallenge: boolean;
  limitTotal: number;
  limitPerType: number;
  limitTimeAmount: number;
  limitTimeScale: number;
  techniques: string[];
  badge: Badge;
  hideUntilEarned: boolean;
  points: Point[];
  extra: any;
  rewards: any[];
  i18n: I18n;
  badgeUrl: string;
  _id: string;
}

export interface IChallengeCategory {
  _id: string;
  title: string;
}

export async function getChallenges(player: string) {
  const { data } = await api.get(`challenge`, {
    params: {
      orderby: "extra.order",
      reverse: true,
      available: true,
      player,
    },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data.filter((item) => item.techniques.includes("GT35") && item.available);
  }
}

export async function getChallengeById(id?: string) {
  const { data } = await api.get<IChallenge>(`challenge/${id}`);

  return data;
}

export async function getQuickChallenges(filters?: any) {
  const { data } = await api.get(`challenge`, {
    params: {
      orderby: "extra.order",
      reverse: true,
      q: "'extra.hidden':null,active:true",
    },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data.filter((item) => item.techniques.includes("GT17"));
  }
}

export async function getAllChallenges(player?: any) {
  const { data } = await api.get(`challenge`, {
    params: {
      orderby: "extra.order",
      reverse: true,
      available: true,
      player,
    },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getChallengeCategory() {
  const { data } = await api.get<IChallengeCategory>(
    `database/challenge_category__c`,
    {
      params: {
        orderby: "order",
        reverse: true,
      },
    }
  );
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}
