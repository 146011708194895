import ArticlePlaceholder from "components/ui/article-placeholder";
import { useEffect, useState } from "react";
import { fetchNews, INews } from "services/news.service";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";

import "./style.scss";

function formatDate(d: Date) {
  if (isToday(d)) {
    return `Today`;
  }

  if (isYesterday(d)) {
    return `Yesterday`;
  }

  return format(d, "MM/dd/yyyy");
}

export default function NewsList() {
  const [loading, setLoading] = useState(true);
  const [news, SetNews] = useState<INews[]>();

  useEffect(() => {
    setLoading(true);
    async function loadData() {
      const data = await fetchNews();
      SetNews(data);

      setLoading(false);
    }

    loadData();
  }, []);

  function renderLoading() {
    return (
      <>
        <ArticlePlaceholder />
        <ArticlePlaceholder />
        <ArticlePlaceholder />
        <ArticlePlaceholder />
      </>
    );
  }

  function renderList() {
    return (
      <ul className="space-y-3">
        {news?.map((item) => (
          <li
            key={item._id}
            className="flex flex-col border-bottom-dashed pb-4"
          >
            <span className="text-blue-custom mb-2">
              {formatDate(new Date(item.date))}
            </span>

            <div className="flex space-x-2">
              {item.image && item.image.medium ? <div>
                <div className="w-[75px] h-[51px] rounded-md object-cover block">
                  <img
                    className="w-[75px] h-[51px] rounded-md object-cover block"
                    src={item.image.medium.url}
                    alt={item.title}
                  />
                </div>
              </div> : null}

              <div className="flex flex-col">
                <h3 className="text-gold leading-none">{item.title}</h3>
                <div className="content text-grey-custom" dangerouslySetInnerHTML={{__html:item.description}}></div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return <>{loading ? renderLoading() : renderList()}</>;
}
