/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosError } from 'axios';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { actionLog } from "services/actionlog.service";
import { fetchUser, signInRequest } from 'services/auth.service';
import {
  // updateUserProfile,
  updateUserStatus,
  // uploadPicture,
} from 'services/profile.service';
import { User } from 'types/user';
import { clearStorage } from 'utils/storage';

import { api } from '../../services/api';
import { setAuthorizationHeader } from '../../services/interceptors';
import { createTokenCookies, getToken, removeTokenCookies } from '../../utils/tokenCookies';

interface SignInCredentials {
  username: string;
  password: string;
  profile?: { url: string; };
}

interface AuthContextData {
  login: (credentials: SignInCredentials) => Promise<void | AxiosError>;
  loginWithToken: (access_token: string) => Promise<void>;
  signOut: (pathname?: string) => void;
  refreshUser: () => void;
  user?: User;
  isAuthenticated: boolean;
  loadingUserData: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | undefined>();
  const [loadingUserData, setLoadingUserData] = useState(true);
  const navigate = useNavigate();
  const token = getToken();
  const isAuthenticated = Boolean(token);

  const signOut = useCallback(
    (pathname?: string) => {
      removeTokenCookies();
      clearStorage();
      setAuthorizationHeader(api.defaults, '');
      setUser(undefined);
      setLoadingUserData(false);
      navigate(pathname || '/login');
    },
    [navigate]
  );

  async function login({ username, password }: SignInCredentials) {
    try {
      const auth = await signInRequest({ username, password });

      if (auth.message) {
        throw auth;
      }

      const { access_token, refreshToken, permissions, roles } = auth;

      createTokenCookies(access_token, refreshToken);
      setAuthorizationHeader(api.defaults, access_token);

      const data = await fetchUser();

      if(!data){
        throw new Error("invalid user");
      }

      // const { name, image } = data;

      const userData = { _id: data._id, ...data, permissions, roles };

      setUser(userData);

      return userData;
    } catch (error) {
      const err = error as AxiosError;
      console.log(err);
      throw err;
    }
  }

  async function loginWithToken(access_token: string) {
    try {
      if (!access_token) {
        return;
      }

      createTokenCookies(access_token, '');
      setAuthorizationHeader(api.defaults, access_token);

      const data = await fetchUser();

      if(!data){
        throw new Error("invalid user");
      }

      const { name, image, extra } = data;

      const userData = { _id: data._id, ...data, extra, name, image, permissions: null, roles: null };

      setUser(userData);

      return userData;
    } catch (error) {
      const err = error as AxiosError;
      // console.log(err);
      throw err;
    }
  }

  async function refreshUser() {
    const updatedUser = await updateUserStatus();
    setUser(updatedUser);
  }

  // useEffect(() => {
  // 	if (!token) signOut(pathname);
  // }, [pathname, token, signOut]);

  useEffect(() => {

    const token = getToken();

    async function getUserData() {
      setLoadingUserData(true);

      try {
        const response = await fetchUser();
        if (response) {
          // console.log("fetchUser",response);

          const { _id, name, image, email, permissions, roles } = response;
          setUser({ ...response, _id, name, image, email, permissions, roles });
        }
      } catch (error) {
        signOut();
      }

      setLoadingUserData(false);
    }

    if (!user && token) {
      setAuthorizationHeader(api.defaults, token);
      getUserData();
    }

    function handleSignoutEvent() {
      signOut();
    }
    document.addEventListener('signout', handleSignoutEvent, false);
    return () => {
      document.removeEventListener('signout', handleSignoutEvent);
    };

  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loadingUserData,
        login,
        loginWithToken,
        signOut,
        refreshUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };