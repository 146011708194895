/* eslint-disable react-hooks/exhaustive-deps */
import ArticlePlaceholder from 'components/ui/article-placeholder';
import Box from 'components/ui/box';
import Button from 'components/ui/button';
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import { RiCoinsLine } from 'react-icons/ri';
import ReactModal from 'react-modal';
import { useNavigate, useParams } from 'react-router';
import { getVirtualGood, purchaseItem } from 'services/store.service';

export default function ShopDetail() {
  const [virtualGood, setVirtualGood] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [purchased, setPurchased] = useState(false);

  const { user, refreshUser } = useAuth();

  const params = useParams();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);

  ReactModal.setAppElement('#modal');

  function handleCloseModal() {
    navigate('../');
  }

  function afterOpenModal() {}

  useEffect(() => {
    if (!params.id || !user) {
      return;
    }
    const loadData = async () => {
      try {
        const good = await getVirtualGood(params.id as string);
        // console.log(good)
        setVirtualGood(good);
        setPurchased(isPurchased(good));
      } catch (err) {}
      setLoading(false);
    };
    loadData();
  }, [params, user]);

  function isPurchased(good?: any) {
    if (!user?.catalog_items) return false;
    try {
      const purchased = Object.keys(user?.catalog_items).find((item: any) => {
        console.log('isPurchased', item, good._id);
        return item === good._id;
      });
      return !!purchased;
    } catch (e) {
      return false;
    }
  }

  function verifyRequirements() {
    // atualmente está verificando apenas um tipo de ponto
    // precisa refatorar para aceitar multiplos points no futuro
    const requirement = virtualGood?.requires
      .filter((item: any) => item.type === 0)
      .reduce((acc: any, item: any) => {
        acc = item.total;
        return acc;
      }, -1);

    return requirement <= currentBalance();
  }

  function currentBalance() {
    try {
      return user?.point_categories.coins || 0;
    } catch (err) {
      return 0;
    }
  }

  async function handleRedeem(item: any) {
    if (!user) return;

    try {
      if (item.extra.url) {
        window.open(item.extra.url, '_blank');
      } else {
        setProcessing(true);
        setPurchased(false);
        const purchaseResult = await purchaseItem(item._id, user._id);
        if (purchaseResult && purchaseResult.achievements.length) {
          await refreshUser();
          setPurchased(true);
        }
        setProcessing(false);
      }
    } catch (err) {
      setProcessing(false);
    }
  }

  function renderLoading() {
    return (
      <div className="text-center p-4 w-full">
        <ArticlePlaceholder />
      </div>
    );
  }

  function invalidItem() {
    return <div className="text-center p-4">Invalid Item</div>;
  }

  function renderItem() {
    if (!virtualGood) {
      return null;
    }

    return (
      <div className="flex flex-col w-full justify-center items-center space-y-4">
        <div className="flex w-full flex-row items-center space-x-5">
          {virtualGood.image && (
            <div className="w-64 bg-gray-100 rounded-xl overflow-hidden aspect-square">
              <img src={virtualGood.image.original.url} alt={virtualGood.name} className="w-full h-full object-contain" />
            </div>
          )}
          <div className='space-y-3'>
            <div className="text-3xl font-bold">{virtualGood.name}</div>
            {!!virtualGood.description && (
              <div className="w-full text-center border border-solid">{virtualGood.description}</div>
            )}

            {virtualGood.requires.length > 0 && (
              <div className={`flex bg-white rounded-xl shadow-lg text-orange-500 flex-row items-center justify-center font-bold text-2xl p-3 ${!verifyRequirements() ? 'text-red-500' : ''}`}>
                {virtualGood.requires[0].total} <RiCoinsLine size={32} />
              </div>
            )}
          </div>
          
        </div>

        {processing && (
          <div className="p-4 flex items-center">
            <Button
              color='outline'
              className='text-xl'>
              Processing purchase...
              </Button>
          </div>
        )}

        {!processing && !purchased && verifyRequirements() && (
          <div className="p-4 flex items-center">
            <Button
              color='success'
              className='text-xl'
              onClick={() => handleRedeem(virtualGood)}
            >
              Purchase
            </Button>
          </div>
        )}

        {!processing && purchased && (
          <div className="p-4 flex items-center">
            <Button
              color='outline'
              className='text-xl pointer-events-none'>
              Purchased item
            </Button>
          </div>
        )}

        {!processing && !verifyRequirements() && (
          <div className="flex items-center">
            <Button
            disabled
              color='danger'
              className='text-xl'>
              You don&apos;t have enough WOWler coins
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <ReactModal
      key={params.id}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onAfterClose={handleCloseModal}
      contentLabel="Example Modal"
      portalClassName=""
      overlayClassName="fixed z-50 top-0 left-0 w-full h-full bg-black flex flex-col items-center justify-center"
      contentElement={() => (
        <Box className="flex flex-col justify-center items-center w-full md:w-[500px] space-y-4">
          <div className="flex w-full flex-row items-center justify-between">
            <h1 className="text-2xl font-bold">Gamma•X Store</h1>
            <Button onClick={() => setIsOpen(false)}>Close</Button>
          </div>

          <div className={`flex w-full p-3 rounded-xl flex-row items-center justify-between leading-none bg-white`}>
            <b className='text-gray-500'>YOUR BALANCE:</b>
            <span className="flex flex-row items-center text-lg text-orange-500">
              <b>{currentBalance()}</b> <RiCoinsLine size={28} />
            </span>
          </div>

          {loading && renderLoading()}
          {!virtualGood && !loading && invalidItem()}
          {!!virtualGood && !loading && renderItem()}
        </Box>
      )}
    />
  );
}
