import { api } from "./api";

export interface Small {
  url: string;
}

export interface Medium {
  url: string;
}

export interface Original {
  url: string;
}

export interface Image {
  small: Small;
  medium: Medium;
  original: Original;
}

export interface INews {
  _id: string;
  description: string;
  created: number;
  title: string;
  image: Image;
  updated: number;
  date: number;
}

export async function fetchNews() {
  const { data } = await api.get<INews[]>(`database/news__c`);
  return data;
}
