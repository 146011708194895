import Leaderboard from 'components/leaderboard';
import { AuthContext } from 'context/AuthContext';
import { useContext, useState, useEffect } from 'react';
import { getLeaderboardLeaders, getLeaderboards, getPlayerPosition } from 'services/leaderboard.service';

export function LeaderboardList(){

  const { user } = useContext(AuthContext);

  // const [loadingLeaders, setLoadingLeaders] = useState(true);

  const [competitionList, setCompetitionList] = useState<any[]>();
  const [currentCompetition, setCurrentCompetition] = useState<any>();
  const [currentLeaders, setCurrentLeaders] = useState<any>();
  const [userPosition, setUserPosition] = useState<any>();

  useEffect(() => {
    async function loadData() {
      if(!user) return;
      // setLoadingLeaders(true);
      setCurrentLeaders(undefined);
      const leadersResult = await getLeaderboardLeaders(currentCompetition.collection, currentCompetition.limit);
      
      // const top3leaders = leadersResult.filter((item: any) => item.position < 4);
      setCurrentLeaders(leadersResult);
      if(currentCompetition.collection === 'cache_ranking_week__c') {
        const positionUser = await getPlayerPosition(user._id);
        setUserPosition(positionUser);
      } else {
        setUserPosition([]);
      }
      // setLeaderWowlers(top3leaders);
      // setLoadingLeaders(false);
    }

    if (currentCompetition) {
      loadData();
    }
  }, [user,currentCompetition]);

  

  useEffect(() => {
    async function loadData() {
      try {
        // setLoadingLeaders(true);
        const competitionResults = await getLeaderboards();
        setCompetitionList(competitionResults);

        if (competitionResults && competitionResults.length > 0) {
          // setCurrentCompetition(competitionResults[0]);
        }
      } catch (e) {}
    }

    if (user) {
      loadData();
    }
  }, [user]);

  return (
    <Leaderboard data={competitionList} userPosition={userPosition} leaders={currentLeaders} onSelect={(e)=>{setCurrentCompetition(e)}} />
  )
}