import UserInfo from "components/user-info";
import { useMediaQuery } from "react-responsive";

import './styles.scss'

export default function Header() {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 819px)' });
  
  if(!isTabletOrMobile){
    return null;
  }

  return (
    <nav className="main-navbar top-0 sticky z-40  rounded-b-[30px] w-full flex flex-col items-center justify-between px-2 md:px-5 shadow-xl">
        <div className="w-full md:w-auto flex flex-row items-center">
          <div className="flex-1 md:flex-0">
            <UserInfo />
          </div>
        </div>
      </nav>
  );
}
