/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/AuthContext';

import Logo from '../../assets/img/logo.png';

export function Login() {
  const [loading, setLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  // const [errors, setErrors] = useState<any>({});
  const { loginWithToken, login } = useContext(AuthContext);

  useEffect(() => {
    async function doLogin(username: string, password: string) {
      try {
        await login({ username, password });
      } catch (e) {
        setLoading(false);
        setInvalidUser(true);
      }
    }

    async function doLoginWithToken(token: string) {
      try {
        await loginWithToken(token);
      } catch (e) {
        setLoading(false);
        setInvalidUser(true);
      }
    }

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const agent = params.get('agent');
    const secret = params.get('secret');
    if (token) {
      setLoading(true);
      doLoginWithToken(token);
    } else if (agent && secret) {
      setLoading(true);
      doLogin(agent, secret);
    } else {
      setInvalidUser(true);
    }
  }, []);

  return (
    <div className="absolute left-0 right-0 min-h-full m-auto px-5 md:w-1/2 xl:w-1/3 2xl:w-1/4 flex flex-col items-center justify-center">
      <div>
        <img src={Logo} className="pointer-events-none h-24" alt="HughesNet Logo" />
      </div>

      {loading && !invalidUser && (
        <div className="w-full border p-3 mt-5 rounded-full text-center text-gray font-bold">LOADING...</div>
      )}

      {invalidUser && (
        <>
          <div className="w-full border p-3 mt-5 rounded-full text-center text-gray font-bold">
            You must login to access this page
          </div>
        </>
      )}

      {/* {invalidUser && (
        <div className="w-full border p-3 mt-5 rounded-full text-center text-gray font-bold">
          YOU DON'T HAVE ACCESS TO THIS PAGE
        </div>
      )} */}
    </div>
  );
}
