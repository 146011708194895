import { UIElementProps } from "types/prop-types";

export function Input(props:UIElementProps){
	return <input {...props} className={`border-0 rounded-full text-black ${props.className}`} />;
}

export function TextArea(props:UIElementProps){
	return <textarea {...props} rows={3} className={`pl-3 pr-8 py-0 border-0 rounded-lg  text-black ${props.className}`}></textarea>;
}

export function Checkbox(props:UIElementProps){
	return <input type={"checkbox"} {...props} className={`${props.className}`} />;
}
