import { Link } from "react-router-dom";

import "./style.scss";

type PropsHeader = {
  title: string;
  url?: string;
};

export default function SubHeader({ title, url }: PropsHeader) {
  return (
    <div className="flex items-center justify-between my-5">
      <div className="relative pl-2 flex items-center h-[25px]">
        <div className="custom-pipe" />
        <h2 className="text-green-custom text-xl">{title}</h2>
      </div>

      {url && (
        <Link className="text-gold font-bold" to={url}>
          see all
        </Link>
      )}
    </div>
  );
}
