import Button from 'components/ui/button';
import { AuthContext } from 'context/AuthContext';
import { ModalContext } from 'context/ModalContext';
import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { actionLog } from 'services/actionlog.service';
import { registerInspirationalMessage } from 'services/checkin.service';
import { inputClass } from 'styles';

// const wowCheckin = require('assets/img/btn-wow-checkin.png');

export function InspirationalModal() {
  const modalID = 'inspirational';

  const [modalIsOpen, setIsOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { openModal, closeModal } = useContext(ModalContext);

  // const [challenge, setChallenge] = useState<any>();
  const [author, setAuthor] = useState('');
  const [details, setDetails] = useState('');

  function afterOpenModal() {}

  async function handleOnClick() {
    try {
      const message = await registerInspirationalMessage({
        player: user?._id,
        status: 'published',
        author: author,
        message: details,
        publish_date: {$date: new Date().getTime()},
      });

      const res = await actionLog('share_inspirational_messages', {
        message_id: message._id,
      });

      if (res.achievements) {
        const hasAchievement = res.achievements.find((achievement: any) => achievement.type === 1);
        if (!!hasAchievement) {
          window.postMessage({ type: 'achievement', value: { name: 'Share Inspirational Messages' } }, '*');
        }
      }

      closeModal();
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    setIsOpen(openModal === modalID);
    setAuthor('');
    setDetails('');
  }, [openModal]);

  ReactModal.setAppElement('#modal');

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        contentLabel="Example Modal"
        portalClassName=""
        shouldCloseOnEsc
        overlayClassName="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div className="flex flex-col md:w-1/2 bg-white p-5 rounded-xl ">
            <div className="flex flex-row justify-between items-start py-3">
              <p className="text-2xl font-bold text-center relative">Inspire your colleagues!</p>
              <Button onClick={() => closeModal()}>CLOSE</Button>
            </div>

            <div className="overflow-auto space-y-2">
              <div className="flex-1 space-y-2">
                <p>Message</p>
                <textarea className={inputClass} onChange={(e) => setDetails(e.target.value)}></textarea>
              </div>

              <div className="flex-1 space-y-2">
                <p>Author</p>
                <input className={inputClass} onChange={(e) => setAuthor(e.target.value)} />
              </div>
            </div>

            <Button className={`m-5 ${(!author || !details) && 'opacity-50'}`} disabled={!author || !details} onClick={handleOnClick}>
              SEND MESSAGE
            </Button>
          </div>
        )}
      />
    </>
  );
}
