/* eslint-disable react-hooks/exhaustive-deps */

import ChallengeActivity from 'components/challenge-activity';
import { Input } from 'components/form';
import { H1 } from 'components/typography';
import Box from 'components/ui/box';
import Button from 'components/ui/button';
import { useAuth } from 'context/AuthContext';
import { useState, useRef, useEffect } from 'react';
import { BiTrash, BiUser } from 'react-icons/bi';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { updateUserProfile, uploadPicture } from 'services/profile.service';

const characterPlaceholder = require('assets/img/character.png');

let imagePreviewTimeout: any = null;

export function Profile() {
  const navigate = useNavigate();
  const { user, refreshUser } = useAuth();
  const [name, setName] = useState('');
  const [twitter, setTwitter] = useState<any>('');
  const [discord, setDiscord] = useState<any>('');

  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState('');
  const editor = useRef<any>(null);

  // function selectItem(item: any) {
  //   if (isItemLocked(item)) {
  //     toast(`This item is locked. You need to purchase it in the Shop`, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   } else {
  //     setSelectedItem(item.id);
  //   }
  // }

  useEffect(()=>{
    if(user){
      setName(user.name);
      setTwitter(user.extra?.twitter);
      setDiscord(user.extra?.discord);
    }
  },[user]);

  async function handleUpload() {
    let profileImage: any = {};

    setLoading(true);

    if (image) {
      const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
      if (dataUrl) {
        const uploadResult = await uploadPicture(dataUrl);
        if (uploadResult) {
          const url = uploadResult.url;
          profileImage = {
            image: {
              small: { url },
              medium: { url },
              original: { url },
            },
          };
        }
      }
    }

    console.log(profileImage);

    if (!user) return;

    let payload = { _id: user._id, name, extra: {...user.extra, twitter : twitter || null, discord : discord || null} };

    if (profileImage) {
      payload = { ...payload, ...profileImage };
    }

    await updateUserProfile(payload);
    refreshUser();
    toast(`👍 Profile updated!`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    setLoading(false);
  }

  function handleImageChange() {
    if (imagePreviewTimeout) {
      clearTimeout(imagePreviewTimeout);
    }

    imagePreviewTimeout = setTimeout(() => {
      getImageUrl();
    }, 1000);
  }

  function handleClearImage() {
    const profileImage = document.getElementById('profile-image');
    if (profileImage) {
    }
    setImage('');
  }

  const getImageUrl = async () => {
    if (!editor.current) return;
    const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result.blob();

    const profileImage = document.getElementById('profile-image');
    if (profileImage) {
      profileImage.style.backgroundImage = `url(${window.URL.createObjectURL(blob)})`;
    }

    // return window.URL.createObjectURL(blob)
  };

  function getImagePlaceHolder() {
    if (!image) {
      if (user && user.image && user.image.medium) {
        return { backgroundImage: `url(${user.image.medium.url})` };
      } else {
        return {
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: `url(${characterPlaceholder})`,
          opacity: '0.4',
        };
      }
    }

    return {};
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    handleUpload();
  }

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 819px)' });

  return (
    <div className="md:container mx-auto">
      <div className="absolute top-10 left-10">
        <Link to="/" children={<Button>BACK</Button>} />
      </div>

      <Box>
        <div className="text-white flex flex-row px-1 pb-3 md:py-3 space-x-5 items-center">
          <div className="w-16 h-16 flex-0 md:w-24 md:h-24 border rounded-full bg-black text-white flex flex-row items-center justify-center">
            <BiUser size={isTabletOrMobile ? 32 : 64} />
          </div>
          <div className="flex-1 leading-tight">
            <H1>Profile</H1>
            <p>Check out your latest points and achievements</p>
          </div>
        </div>

        <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-3 md:flex-row pb-[80px] p-3">
          <div className="md:w-1/3">
            <h2 className="text-xl mb-3">Edit your Profile</h2>
            <div className="border border-green-custom rounded-lg p-3 bg-grey-dark">
              <form onSubmit={handleSubmit} className="p-3 space-y-5 flex flex-col items-center text-center">
                <div>
                  <div
                    className="rounded-full relative z-2 mt-2 rounded-lg bg-black w-64 h-64"
                    style={getImagePlaceHolder()}
                  >
                    <Dropzone
                      onDrop={(dropped: any) => setImage(dropped[0])}
                      multiple={false}
                      maxFiles={1}
                      noClick={!!image}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <AvatarEditor
                            ref={(e: any) => (editor.current = e)}
                            onImageChange={handleImageChange}
                            width={256}
                            height={256}
                            image={image}
                            borderRadius={256}
                            border={0}
                            className="overflow-hidden rounded-full"
                          />
                          <input {...getInputProps()} />
                        </div>
                      )}
                    </Dropzone>
                    {!!image && (
                      <button
                        type="button"
                        onClick={handleClearImage}
                        className="absolute bottom-0 right-0 rounded-full flex flex-row items-center justify-center text-red-500 bg-white w-16 h-16"
                      >
                        <BiTrash size={32} />
                      </button>
                    )}
                  </div>
                </div>

                <div className="w-full">
                  <p className="text-lg">
                    <b>DISPLAY NAME</b>
                  </p>
                  <Input
                    onChange={(e: any) => setName(e.target.value)}
                    required
                    name="name"
                    type="text"
                    className="mt-2 w-full text-center py-2 bg-black text-white border-2 border-green-custom"
                    defaultValue={user?.name}
                  />
                </div>

                <div className="w-full">
                  <p className="text-lg">
                    <b>TWITTER ACCOUNT</b>
                  </p>
                  <div className="flex flex-row items-center rounded-full mt-2 w-full text-center bg-black text-white border-2 border-green-custom">
                    <div className="text-xl font-bold pl-3 leading-none">
                      <span>@</span>
                    </div>
                    <Input
                      onChange={(e: any) => setTwitter(e.target.value)}
                      name="twitter"
                      placeholder="username"
                      type="text"
                      className="focus:ring-0 focus:border-none focus:outline-none p-2 flex-1 bg-black text-white"
                      defaultValue={user?.extra?.twitter}
                    />
                  </div>
                </div>

                <div className="w-full">
                  <p className="text-lg">
                    <b>DISCORD USER</b>
                  </p>
                  <div className="flex flex-row items-center rounded-full mt-2 w-full text-center bg-black text-white border-2 border-green-custom">
                    <Input
                      onChange={(e: any) => setDiscord(e.target.value)}
                      name="discord"
                      placeholder="discord user"
                      type="text"
                      className="focus:ring-0 focus:border-none focus:outline-none px-4 py-2 flex-1 bg-black text-white"
                      defaultValue={user?.extra?.discord}
                    />
                  </div>
                </div>

                {loading && (
                  <div className="px-4 py-2 font-bold flex flex-row items-center space-x-4 border border-white border-opacity-50 rounded-full">
                    SAVING CHANGES...
                  </div>
                )}

                {!loading && (
                  <div className="flex flex-row items-center space-x-4">
                    <Button
                      type="button"
                      className="rounded-full bg-red-600 min-w-auto w-auto"
                      onClick={() => navigate('/')}
                    >
                      <FaTimesCircle size={36} />
                    </Button>{' '}
                    <Button type="submit" className="bg-green-600 w-auto">
                      <div className="flex flex-row items-center">
                        <FaCheckCircle size={36} /> <span className="px-3">SAVE</span>
                      </div>
                    </Button>
                  </div>
                )}
              </form>
            </div>
          </div>

          <div className="flex-1">
            <h2 className="text-xl mb-3">Recent Activities</h2>
            <ChallengeActivity />
          </div>
        </div>
      </Box>
    </div>
  );
}
