import { AuthContext } from "context/AuthContext";
import { useContext } from "react";

import "./style.scss";

export function ProgressBar() {
  const { user } = useContext(AuthContext);

  return (
    <div className="relative w-full h-6 border border-solid border-green-custom rounded-3xl bg-grey-dark overflow-hidden">
      <p className="absolute top-0 left-3 text-white font-bold drop-shadow-lg">
        Level {user?.level?.position ? user?.level?.position + 1 : 0}
      </p>
      <div
        className={`h-full gradient-progress rounded-3xl `}
        style={{ width: user?.level_progress?.percent || 0 + "%" }}
      ></div>
    </div>
  );
}
